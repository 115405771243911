<template>
  <div class="reviews">
    <div class="title">
      <h3>
        {{ $t("teacher.profile.review-title") }}
      </h3>
    </div>
    <div v-if="comment.data.length > 0">
      <div v-for="(item, key) in comment.data" :key="key" class="item">
        <div class="d-flex justify-content-between">
          <div>
            <div class="reviewed-star star">
              <star-rating :rating="Number(item.rate)" :star-size="18" :show-rating="false" :read-only="true" />
              {{ _totalRate(item.rate) }}
            </div>
          </div>
          <small class="date">{{ formatDateInstance(item.created_at) }}</small>
        </div>
        <div class="descr">
          <p>{{ item.content }}</p>
          <router-link v-if="item.member.role === 'teacher'" :to="'/teacher/' + item.member.id + '/profile'">
            <small><img class="img-profile" :src="item.member.profile_image" />{{ item.member.name }} </small>
          </router-link>
          <router-link v-else :to="'/student/' + item.member.id + '/profile'">
            <small><img class="img-profile" :src="item.member.profile_image" />{{ item.member.name }} </small>
          </router-link>
        </div>
      </div>
    </div>
    <div v-else class="border-top">
      <p>{{ $t("teacher.profile.no_comment_yet") }}</p>
    </div>
    <div class="pt-2 d-flex justify-content-center border-top">
      <button class="btn btn-more" @click="handleLoadMore" :disabled="_noMoreComment" v-if="!_noMoreComment">
        <i class="fa fa-chevron-down" aria-hidden="true"></i> {{ $t("teacher.profile.button.see-more") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      comment: {
        data: [],
        meta: {}
      },
      filter: {
        page: 1,
      },
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      getReviewLesson: "teacherProfile/getReviewLesson",
    }),
    _noMoreComment() {
      let { meta } = this.comment;
      return meta.pagination.last > this.filter.page ? false : true;
    },
  },
  async created() {
    await this.fetchCommentLesson({
      id: this.$route.params.teacherId,
      limit: 3,
      page: this.filter.page,
    });
    this.comment = this.getReviewLesson;
  },
  methods: {
    ...mapActions({
      fetchCommentLesson: "teacherProfile/fetchCommentLesson",
    }),
    async handleLoadMore() {

      if (this.loading) return;
      this.loading = true;
      this.filter.page = this.filter.page + 1;
      await this.fetchCommentLesson({
        id: this.$route.params.teacherId,
        limit: 3,
        page: this.filter.page,
      });
      let items = this.comment.data;
      this.comment = {
        meta: this.getReviewLesson.meta,
        data: items.concat(this.getReviewLesson.data)
      };
      this.loading = false;
    },
    _totalRate(rate) {
      rate = rate.toFixed(1);
      return rate;
    },
  },
};
</script>
